import React from "react";
import "./App.css";
import PublicLeaderboard from "./components/PublicLeaderboard";
import ListofEvents from "./components/ListofEvents";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";

function BlogPost() {
  let { id } = useParams();
  let { id2 } = useParams();
  return <PublicLeaderboard />;
}

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <ListofEvents />
        </Route>
        <Route path="/:id/:id2">
          <BlogPost />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
