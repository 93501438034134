import React, { useState, useEffect } from "react";
import axios from "./../axios/axios";
import "react-data-table-component-extensions/dist/index.css";
import share from "./share.png";
import Moment from "react-moment";

const ListofEvents = () => {
  const [_data, setData] = useState([]);
  const [noData, setNoData] = useState(false);

  const getReferrals = () => {
    axios.get(`/referral-campaigns`).then((res) => {
      setNoData(true);
      if (res.status == 200) {
        setData(res.data.campaigns);
      } else {
        console.log("Error");
      }
    }).catch((error)=>{
      console.log(error);
    })
  };

  useEffect(() => {
    getReferrals();
  }, []);

  return _data.length != 0 ? (
    <div className="py-5 listeventsBody h-100">
      <div className="container">
        <div className="contentPublic">
          <h1 className="text-center text-white">
            Referral {_data.length > 1 ? "Contests" : "Contest"}
          </h1>
        </div>
        <div className="row justify-content-center">
          {_data.map((item, index) => {
            return (
              <div
                className="col-md-4 col-sm-6 col-xs-12 text-center cardMain"
                key={index}
                id={index}
              >
                <div className="card py-4 px-4 h-100">
                  <div className="card-block">
                    <h4 className="card-title mb-3">
                      {item.event_name}
                      <a
                        href={`/${item.event_url}/${item.campaign_url}`}
                        target="_blank"
                        className="card-link"
                      >
                        <img className="ml-2" src={share} />
                      </a>
                    </h4>
                    <h6 className="card-subtitle text-black">
                      {item.campaign_name}
                    </h6>
                    <p className="card-text py-1">
                      <Moment format="DD-MM-YYYY">{item.start_datetime}</Moment>
                      <span> to </span>
                      <Moment format="DD-MM-YYYY">{item.end_datetime}</Moment>
                    </p>

                    <p className="card-text py-1" style={{ color: "#fc0352" }}>
                      Referrers Participating : {item.referral_count}
                      <br />
                      Total Referrals: {item.referee_count}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : noData ? (
    <div
      className="h-100 text-center text-white"
      style={{ background: "black" }}
    >
      <h1 className="align-middle" style={{ transform: "translateY(50%)" }}>
        <strong style={{ textTransform: "capitalize" }}>
          There are no Live Referral Contests
        </strong>
      </h1>
    </div>
  ) : (
    <div
      className="h-100 text-center text-white"
      style={{ background: "black" }}
    >
      <h1 className="align-middle" style={{ transform: "translateY(50%)" }}>
        <strong style={{ textTransform: "capitalize" }}>Loading...</strong>
      </h1>
    </div>
  );
};

export default ListofEvents;
