/** @format */

import axios from "axios";

// https://github.com/axios/axios#config-defaults
let api;
if (window.location.hostname === "referral.konfhub.com") {
  api = "https://api.konfhub.com";
} else if (window.location.hostname == "dev-referral.konfhub.com") {
  api = "https://dev-api.konfhub.com";
} else if (window.location.hostname === "localhost") {
  api = "https://dev-api.konfhub.com";
}
console.log(api);

axios.defaults.baseURL = api;

export default axios;
