/** @format */

/* eslint-disable */

import React, { useState, useEffect } from "react";
import poweredby from "./poweredBylogo.png";
import whatsappIcon from "./whatsappIcon.svg";
import logo from "./tweeticon.png";
import {
  Paper,
  Box,
  AppBar,
  Tab,
  makeStyles,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import axios from "./../axios/axios";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ReactHtmlParser from "react-html-parser";

const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid transparent",
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid transparent",
    color: "black",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "black",
    color: "white",
  },
  indicator: {
    backgroundColor: "#e83e8c",
  },
}));

createTheme("solarized", {
  text: {
    primary: "#268bd2",
    secondary: "#2aa198",
  },
  background: {
    default: "black",
  },
  context: {
    background: "black",
    text: "#FFFFFF",
  },
  divider: {
    default: "#073642",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const PublicLeaderboard = () => {
  let urlArr = window.location.pathname.split("/");
  urlArr.shift();

  const classes = useStyles();
  const [value, setValue] = useState("1");
  const [error, setError] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [header, setHeader] = useState([]);
  const [name, setNames] = useState([]);
  const [_data, setData] = useState([]);
  const [tabValue, setTabValue] = useState(null);
  const [tabId, setTabId] = useState(0);
  const [displayHr, setDisplayHr] = useState(true);
  const [totalRef, setTotalRef] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [regLink, setRegLink] = useState("");
  let classesTooltip = useStylesTooltip();
  const [date, setDate] = useState("");

  let varb = `http://beta.konfhub.com/${urlArr[0]}`;

  const getReferrals = () => {
    let temp = [],
      tempData,
      headers = [],
      names = [];
    axios.get(`/event/referrals/${urlArr[0]}/${urlArr[1]}`).then((data) => {
      if (data.status == 200) {
        // yyyy-MM-dd
        let inputStartDate = data.data.event_start_date;
        inputStartDate = inputStartDate.slice(0, 10);
        const [year, month, day] = inputStartDate.split("-");

        // dd/mm/yyyy
        inputStartDate = `${day}/${month}/${year}`;

        setStartDate(inputStartDate);
        setTotalRef(data.data.total_referees);
        data.data.length != 0
          ? setData(data.data.referrals)
          : "";

        axios.get(`/referral/${urlArr[0]}/${urlArr[1]}`).then((data) => {
          if (data.status == 200) {
            tempData = data.data.campaign_details;
            setRegLink(tempData.referral_registration_url);
            if (tempData.steps.length == 0) {
              setDisplayHr(false);
            }

            if (tempData.prize.length != 0) {
              headers.push(tempData.prize);
              names.push(<Tab label="Prize(s)" key={names.length} />);
            }
            if (tempData.rules.length != 0) {
              headers.push(tempData.rules);
              names.push(<Tab label="Rule(s)" key={names.length} />);
            }
            if (tempData.steps.length != 0) {
              headers.push(tempData.steps);
              names.push(<Tab label="Step(s)" key={names.length} />);
            }
            setNames(names);
            setHeader(headers);
            setTabValue(data.data.campaign_details);

            // yyyy-MM-dd
            let input = data.data.campaign_details.start_datetime.slice(
              0,
              10
            );
            const [year, month, day] = input.split("-");

            // dd/mm/yyyy
            input = `${day}/${month}/${year}`;

            setDate(input);
          } else {
            setError(data.data);
          }
        }).catch((error)=>{
          console.log(error);
        })
      } else {
        setError(data.data);
        console.log("Error");
      }
    }).catch((error)=> {
      console.log(error);
    })
  };

  useEffect(() => {
    getReferrals();
  }, []);

  const handleTabChange = (event, newTabId) => {
    setTabId(newTabId);
  };

  // document.querySelector('#openWA').addEventListener('click', function() {
  //   var f = Date.now(),
  //     j = setTimeout(function() {
  //       if (Date.now() - f > 1250)
  //         return;
  //       alert('WA not installed')
  //     }, 1e3);
  // })

  const detectWhatsapp = (phone, text) => {
    console.log("vfnjkd");
    const uri = `whatsapp://send/?phone=${encodeURIComponent(
      phone
    )}&text=${encodeURIComponent(text)}`;

    const onIE = () => {
      return new Promise((resolve) => {
        window.navigator.msLaunchUri(
          uri,
          () => resolve(true),
          () => resolve(false)
        );
      });
    };

    const notOnIE = () => {
      return new Promise((resolve) => {
        const a =
          document.getElementById("wapp-launcher") ||
          document.createElement("a");
        a.id = "wapp-launcher";
        a.href = uri;
        a.style.display = "none";
        document.body.appendChild(a);

        const start = Date.now();
        const timeoutToken = setTimeout(() => {
          if (Date.now() - start > 1250) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, 1000);

        const handleBlur = () => {
          clearTimeout(timeoutToken);
          resolve(true);
        };
        window.addEventListener("blur", handleBlur);

        a.click();
      });
    };

    return window.navigator.msLaunchUri ? onIE() : notOnIE();
  };

  function bf() {
    detectWhatsapp("<your number here>", "test").then((hasWhatsapp) =>
      alert("You " + (hasWhatsapp ? "have WhatsApp" : "don't have WhatsApp"))
    );
  }

  var columns = [
    {
      name: <span className="boardHeader">Rank</span>,
      selector: "rank",
      sortable: true,
      ignoreRowClick: false,
      center: true,
      minWidth: "25%",
      maxWidth: "25%",
    },
    {
      name: <span className="boardHeader">Name</span>,
      sortable: true,
      ignoreRowClick: false,
      center: true,
      cell: (row) => (
        <div>
          <Tooltip
            arrow
            classes={{
              arrow: classesTooltip.arrow,
              tooltip: classesTooltip.tooltip,
            }}
            title={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                <b>Email ID:</b> {row.email_id}
                <br />
                <b>Phone No:</b> {row.phone_number}
              </span>
            }
          >
            <span>
              <span className="rowNameAttendee">{row.name}</span>
            </span>
          </Tooltip>

          <a
            className="iconWeb"
            id="openWA"
            href={`https://web.whatsapp.com/send?text=I am attending ${tabValue.campaign_name} on ${startDate}. For registration and event details: ${regLink}?referred_by=${row.booking_id}%26referral_mode=whatsapp`}
            // href={`https://twitter.com/intent/tweet?url=${regLink}?referred_by=${row.booking_id}%26referral_mode=twitter&text=Join me at ${tabValue.campaign_name} to attend exciting sessions on ${startDate}. For registration and event details: `}
            target="_blank"
            // onClick={() => bf()}
            rel="noreferrer"
          >
            {/* <span className="iconWeb"> <i className="fas fa-whatsapp"></i> </span> */}
            <img src={whatsappIcon} />
          </a>
        </div>
      ),
    },
    {
      name: <span className="boardHeader">No. of Referrals</span>,
      selector: "no_of_referrals",
      sortable: true,
      center: true,
      ignoreRowClick: false,
      minWidth: "33%",
      maxWidth: "33%",
    },
  ];

  return (
    <>
      {tabValue != null ? (
        <Paper
          className="publicLeaderBoard text-center"
          style={{ backgroundColor: "black" }}
        >
          <div className="contentPublic">
            <h1>
              <strong style={{ textTransform: "capitalize" }}>
                {tabValue.campaign_name} Leaderboard &nbsp;
                <span className="badgeCus">
                  {tabValue.status == 1
                    ? "Draft"
                    : tabValue.status == 2
                    ? "Scheduled"
                    : tabValue.status == 3
                    ? "Live"
                    : tabValue.status == 4
                    ? "Stopped"
                    : "Completed"}
                </span>
              </strong>
            </h1>
            <h4>
              <strong style={{ textTransform: "capitalize", color: "white" }}>
                <a href={regLink} target="_blank">
                  {tabValue.event_name}
                </a>
              </strong>
            </h4>

            <div className="heading-text">
              {/* <strong>
                We are currently removing the referrals that violate the
                referral rules.
                <br />
                The leaderboard will reflect the updated counts from time to
                time.
              </strong> */}

              <div className="contentNotice mt-4">
                <strong className="contentNotice mt-4">
                  {ReactHtmlParser(tabValue.notice)}
                </strong>
              </div>
            </div>

            <div className="publicLeaderboardTableWrapper">
              <DataTableExtensions
                columns={columns}
                data={_data}
                print={false}
                export={false}
                filterPlaceholder="Search..."
              >
                <DataTable
                  responsive={true}
                  columns={columns}
                  pagination
                  data={_data}
                  customTheme={myTheme}
                  noDataComponent={
                    <span className="my-3">No Referrals Made</span>
                  }
                  overflowY={true}
                  className="publicLeaderboardTable"
                />
              </DataTableExtensions>
            </div>

            <p className="alert-msg" id="alert_msg">
              * Duplicate / non-existent email-ids as referrals will be
              considered invalid and will lead to disqualification{" "}
            </p>
            <div className="d-flex justify-content-center buttonSubecribeCus">
              <div className="ref-cus m-2">
                <h5 className="sum-ref">
                  Total Referrals: <span id="msgs">{totalRef}</span>{" "}
                </h5>
              </div>

              {tabValue.special_button ? (
                Object.keys(tabValue.special_button).length != 0 ? (
                  tabValue.special_button.text.trim() != "" ? (
                    <div className="news-cus m-2" style={{ marginTop: "-3px" }}>
                      <a
                        className="btn btn-outline-warning"
                        href={tabValue.special_button.url}
                        target="_blank"
                      >
                        {tabValue.special_button.text}
                      </a>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="tabs-cus">
              <div className={classes.root}>
                {!tabValue.enabled ? (
                  <TabContext value={value}>
                    <AppBar position="static">
                      <TabList
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        className="launchTabs"
                        classes={{
                          indicator: classes.indicator,
                        }}
                      >
                        <Tab label="Prize(s)" value="1" />
                        <Tab label="Steps" value="2" />
                        <Tab label="Rule(s)" value="3" />
                      </TabList>
                    </AppBar>
                    <TabPanel className="text-left pl-0 tabCusText" value="1">
                      {ReactHtmlParser(tabValue.prize)}
                    </TabPanel>
                    <TabPanel className="text-left pl-0 tabCusText" value="2">
                      {ReactHtmlParser(tabValue.steps)}
                    </TabPanel>
                    <TabPanel className="text-left pl-0 tabCusText" value="3">
                      {ReactHtmlParser(tabValue.rules)}
                    </TabPanel>
                  </TabContext>
                ) : (
                  <>
                    <Tabs
                      value={tabId}
                      onChange={handleTabChange}
                      classes={{
                        indicator: classes.indicator,
                      }}
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      {name.map((item) => item)}
                    </Tabs>
                    {displayHr ? <div className="hrCus"></div> : ""}
                    {header.map((item, index) =>
                      tabId == index ? (
                        <div
                          className="text-left pl-0 tabCusText"
                          style={{ margin: "1em 1em 0em 1em" }}
                          key={index}
                        >
                          {index == tabId ? ReactHtmlParser(header[index]) : ""}
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-md-6 text-center pt-5">
                <img
                  src={poweredby}
                  style={{
                    width: "90%",
                    height: "90px",
                    // backgroundColor: "white"
                  }}
                />
              </div>
            </div>
          </div>
        </Paper>
      ) : error != null ? (
        <div
          className="h-100 d-flex justify-content-center"
          style={{ background: "black" }}
        >
          <div className="jumbotron my-auto">
            <h1 className="display-5">No Such Referral Contest Available</h1>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const myTheme = {
  title: {
    fontSize: "22px",
    fontColor: "#1f2933",
    fontWeight: "800",
    backgroundColor: "transparent",
    height: "56px",
  },
  header: {
    fontSize: "15px",
    fontWeight: "500",
    fontColor: "#FFFFFF",
    fontColorActive: "#FFFFFF",
    backgroundColor: "black",
    height: "48px",
    denseHeight: "32px",
  },
  contextMenu: {
    backgroundColor: "black",
    fontSize: "18px",
    fontColor: "rgba(0,0,0,.87)",
    transitionTime: "225ms",
  },
  rows: {
    // default || spaced
    spacing: "default",
    fontSize: "14px",
    fontColor: "hsl(0, 0%, 29%)",
    backgroundColor: "black",
    borderWidth: "1px",
    borderColor: "white",
    stripedColor: "BCBFC2",
    hoverFontColor: "#000000",
    hoverBackgroundColor: "#C1C4C6",
    hoverBorderColor: "#8F86FF",
    height: "48px",
    denseHeight: "32px",
  },
  cells: {
    cellPadding: "48px",
    fontWeight: "600 !imprtant ",
    maxWidth: "200px",
  },
  expander: {
    fontColor: "rgba(0,0,0,.87)",
    expanderColor: "rgba(0,0,0,.54)",
    expanderColorDisabled: "rgba(0,0,0,.12)",
    backgroundColor: "transparent",
  },
  pagination: {
    fontSize: "13px",
    fontColor: "rgba(0,0,0,.54)",
    backgroundColor: "black",
    buttonFontColor: "rgba(0,0,0,.54)",
    buttonHoverBackground: "rgba(0,0,0,.12)",
  },

  columnDefs: [{ width: "20%", targets: 0 }],
};

export default PublicLeaderboard;
